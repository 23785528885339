import { COLLECTIONS_API, NAV_COLLECTIONS_API } from "../../../../Utilities/APIs";
import PSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/PSCRUD/PSCRUD";

function NavCollections() {
  return (
    <PSCRUD
        // common props
        api={NAV_COLLECTIONS_API}
        screenTopicSingular={'Nav Collection'}
        screenTopicPlural={'Nav Collections'}
        
        // crud header props
        showTotalInHeading
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete

        // field props
        maxPrecedence={3}
        
        // select props
        selectApi={COLLECTIONS_API}
        selectType='hand-pick'
        selectDBField='collectionID'
        selectName='Collection'
    />
  )
}

export default NavCollections