 import { Link } from 'react-router-dom'
//  import { RiEditBoxLine } from 'react-icons/ri'

import './ProfileInfo.css'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../../Redux/Features/Auth/authSlice'

function ProfileInfo() {

    const {employee} = useSelector(state => state.auth)
    const dispatch = useDispatch()

    return (
        <div className="profile_info">
            <div className="default_info">
                <div className="edit_btn">
                    {/* <h4>Profile Info</h4> */}
                    {/* <div className="">
                        <Link className='btn_text' to='/edit-profile-info'>edit</Link>
                        <RiEditBoxLine />
                    </div> */}
                    {/* <div className="profile_buttons"> */}
                        <Link to='/change-password' className='btn_text'>change password</Link>
                        {/* <Link to="/login" className='btn_text'>log out</Link> */}
                        <button 
                            onClick={() => dispatch(logout())}
                            className='btn_text'
                        >
                            log out
                        </button>
                    {/* </div> */}
                </div>
                <div className="grid_container">
                    <div className="info_content">
                        <p className='input_field_label'>Name</p>
                        <p className="status">{employee?.name}</p>
                    </div>
                    <div className="info_content">
                        <p className='input_field_label'> Email</p>
                        <p className="status">{employee?.email}</p>
                    </div>
                    <div className="info_content">
                        <p className='input_field_label'> Level</p>
                        <p className="status">{employee?.level}</p>
                    </div>
                    {/* <div className="info_content">
                        <p className='input_field_label'>Gender</p>
                        <p className="status">male</p>
                    </div>
                    <div className="info_content">
                        <p className='input_field_label'> Date of Birth</p>
                        <p className="status">31.12.93</p>
                    </div> */}
                </div>
            </div>
            {/* <div className="profile_buttons">
                <Link to='/change-password' className='btn_text'>change password</Link>
                <Link to="/login" className='btn_text'>log out</Link>
            </div> */}
        </div>
    )
}

export default ProfileInfo