import { RiGridLine } from "react-icons/ri";
import { CATEGORIES_API, SUBCATEGORIES_API } from "../../../../Utilities/APIs";
// import DNPSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/DNPSCRUD/DNPSCRUD";
import PcDNPSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/DNPSCRUD/PcDNPSCRUD";

function SubCategories() {
  return (
    <PcDNPSCRUD

        // parentChild props
        parentDBField='category'

        // common props
        api={SUBCATEGORIES_API}
        screenTopicSingular={'Subcategory'}
        screenTopicPlural={'Subcategories'}
        
        // crud header props
        showTotalInHeading

        // action button props
        extraActionButton
        extraActionButtonHandleClick='/item_subcategories'
        extraActionButtonChildren={<RiGridLine />}
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete

        // field props
        maxPrecedence={10}
        
        // select props
        selectApi={CATEGORIES_API}
        selectType='parent-child'
        selectDBField='category'
        selectName='Category'
    />
  )
}

export default SubCategories