import { ITEMS_API, ITEM_SUBCATEGORIES_API, SUBCATEGORIES_API } from "../../../../Utilities/APIs"
import PcSSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/PcSSCRUD"
// import SSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/SSCRUD"

function ItemSubcategories() {
  return (
    <PcSSCRUD

        // parentChild props
        parentDBField='subcategory'

        // common props
        api={ITEM_SUBCATEGORIES_API}
        screenTopicSingular='Item Subcategory'
        screenTopicPlural='Item Subcategories'

        // crud header props
        showTotalInHeading

        // select props
        selectApi={SUBCATEGORIES_API}
        selectType='parent-child'
        selectDBField='subcategory'
        selectName='Subcategory'

        // secondSelect props
        secondSelectApi={ITEMS_API}
        secondSelectName='Item'
        secondSelectDBField='item'
    />
  )
}

export default ItemSubcategories