import { HERO_SLIDERS_API } from "../../../../Utilities/APIs";
import ILPCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/ILPCRUD/ILPCRUD";

function HeroSliders() {

  return (

    <ILPCRUD
        // common props
        api = {HERO_SLIDERS_API}
        screenTopicSingular = 'Hero Slider'
        screenTopicPlural = 'Hero Sliders'

        // crud header props
        showTotalInHeading

        // field props
        maxPrecedence={10}
        
        // crud props
        allowCreate
        allowDelete
        allowUpdate
    />

  )
}

export default HeroSliders