import { ITEMS_API, ITEM_SIZES_API, SIZES_API } from "../../../Utilities/APIs";
import PcBSSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/BSSCRUD/PcBSSCRUD";

function ItemSizesFromItem() {
  return (
    <PcBSSCRUD

        // parent props
        parentDBField={'item'}

        // common props
        api={ITEM_SIZES_API}
        screenTopicSingular='Item Size'
        screenTopicPlural='Item Sizes'

        // crud header props
        showTotalInHeading

        // crud props
        allowCreate
        allowUpdate
        allowDelete

        // field props
        booleanDBField='isAvailable'
        booleanName='Is Available'

        // select props
        selectApi={ITEMS_API}
        selectType='parent-child'
        selectDBField='item'
        selectName='Item'

        // secondSelect props
        secondSelectApi={SIZES_API}
        secondSelectName='Size'
        secondSelectDBField='size'
    />
  )
}

export default ItemSizesFromItem