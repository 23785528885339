import { SOCIAL_LINKS_API } from "../../../../Utilities/APIs";
import BLTCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/BLTCRUD/BLTCRUD";

function SocialLinks() {
  return (
    <BLTCRUD
        // common props
        api = {SOCIAL_LINKS_API}
        screenTopicSingular = 'Social Link'
        screenTopicPlural = 'Social Links'
        
        // crud header props
        showTotalInHeading
        
        // crud props
        allowUpdate
        allowCreate
        
        // field props
        booleanDBField='visibility'
        booleanName='Visibility'

        // Text prop
        textDBField="platform"
        textName="Platform"
    />
  )
}

export default SocialLinks