import { useState, useEffect } from "react"
import axios from "axios"
// import { CATEGORIES_API, ITEMS_API } from "../../../../../Utilities/APIs"
import { CATEGORIES_API, ITEMS_API, ITEM_CATEGORIES_API, ITEM_SUBCATEGORIES_API, SUBCATEGORIES_API } from "../../../../../Utilities/APIs"

function ViewItem({

    //common props
    targetID, employee

}) {

    const [item, setItem] = useState(null)
    const [categories, setCategories] = useState(null)
    const [subcategories, setSubcategories] = useState(null)
    const [itemCategories, setItemCategories] = useState(null)
    const [itemSubcategories, setItemSubcategories] = useState(null)
    console.log(item)
    // const [categoryOrSubcategory, setCategoryOrSubcategory] = useState('')
    // const [categoryOrSubcategoryName, setCategoryOrSubcategoryName] = useState('')

    useEffect(() => {

      const config = {
          headers: {
              Authorization: `Bearer ${employee.token}`
          }
      }
  
      async function fetchAndSetItem() {
      
          const {data} = await axios.get(ITEMS_API + targetID, config)

          setItem(data);
      }
  
      fetchAndSetItem()

      // async function fetchAndSetCategoryOrSubCategoryID() {
      //   if (categoryOrSubcategory === 'category') {
      //       const {data} = await axios.get(CATEGORIES_API)
      //       const targetCategory = data.find(category => category._id === item?.category)
      //   }
      // }
  
    // }, [targetID, employee.token, categoryOrSubcategory])
    }, [targetID, employee.token])

    useState(() => {

      async function fetchAndSetCategories() {
        const {data} = await axios.get(CATEGORIES_API)
        setCategories(data)
      }

      async function fetchAndSetItemCategories() {
        const {data} = await axios.get(ITEM_CATEGORIES_API)
        setItemCategories(data)
      }

      async function fetchAndSetSubategories() {
        const {data} = await axios.get(SUBCATEGORIES_API)
        setSubcategories(data)
      }

      async function fetchAndSetItemSubcategories() {
        const {data} = await axios.get(ITEM_SUBCATEGORIES_API)
        setItemSubcategories(data)
      }

      fetchAndSetCategories()
      fetchAndSetSubategories()
      fetchAndSetItemCategories()
      fetchAndSetItemSubcategories()

    }, [])

    return (
      <div className="crud_view_content">

        <h1>Name</h1>
        <p>{item?.name}</p>

        <h1>Description</h1>
        <p>{item?.description}</p>

        <h1>Price</h1>
        <p>{item?.price}</p>

        <h1>Availability</h1>
        <p>{item?.isAvailable === true ? 'TRUE' : 'FALSE'}</p>

        <h1>Visibility</h1>
        <p>{item?.isVisible === true ? 'TRUE' : 'FALSE'}</p>

        <h1>Category</h1>
        <p>
          {
            item?.categoryOrSubcategory === 'category'
            ?
              categories?.find(
                c => c._id === (itemCategories?.find(i => i.item === item?._id)?.category)
              )?.name
            :
              categories?.find(
                c => c._id === (subcategories?.find(
                  s => s._id === (itemSubcategories?.find(
                    i => i.item === item?._id)?.subcategory)
                  )?.category
                ))?.name
          }
        </p>

        {
          item?.categoryOrSubcategory === 'subcategory' &&
          <>
          <h1>Subcategory</h1>
          {
            subcategories?.find(
              s => s._id === (itemSubcategories?.find(i => i.item === item?._id)?.subcategory)
            )?.name
          }
          </>
        }

      </div>
    )
}

export default ViewItem