import { DELIVERY_FEES_API } from "../../../../Utilities/APIs";
import ABNCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/ABNCRUD/ABNCRUD";

function DeliveryFee() {
  return (
    <ABNCRUD

        // common props
        api={DELIVERY_FEES_API}
        screenTopicSingular={'Delivery Fee'}
        screenTopicPlural={'Delivery Fees'}

        // crud header props
        showTotalInHeading

        // crud props
        allowCreate
        allowUpdate
        allowDelete

        // field props
        booleanName={'Is Applicable'}
        booleanDBField={'isApplicable'}

    />
  )
}

export default DeliveryFee