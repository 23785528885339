import { RiMapPin2Fill } from "react-icons/ri"
import NavCard from "../../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../../Partials/Layouts/NavCardList/NavCardList"

function CategoryScreens() {
    return (
        <NavCardList numOfCards={'two'}>
            <NavCard cardName={'Categories'} navCardLink='/categories' ><RiMapPin2Fill /></NavCard>
            <NavCard cardName={'Featured Categories'} navCardLink='/featured_categories' ><RiMapPin2Fill /></NavCard>
        </NavCardList>
    )
}

export default CategoryScreens