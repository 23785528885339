import axios from "axios";
import { useEffect, useState } from "react";
import { RiCheckboxCircleFill, RiCloseCircleFill, RiGiftFill, RiRepeatFill, RiTruckFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { ORDERS_API } from "../../../Utilities/APIs";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";

function OrderScreens() {

    const [orders, setOrders] = useState(null)

    const {employee} = useSelector(state => state.auth)

    useEffect(() => {

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetItems() {
            const {data} = await axios.get(ORDERS_API +'getAllOrders', config)
            setOrders(data)
        }
        fetchAndSetItems()
    }, [employee.token])

    return (
        <ScreenHolder>
            <NavCardList numOfCards='five'>
                <NavCard 
                    cardName={`Processing(${orders?.filter(i => i.orderStatus === 'processing')?.length})`} 
                    navCardLink={'/ordersFromStatus/processing'}
                >
                    <RiRepeatFill />
                </NavCard>
                <NavCard 
                    cardName={`To Be Delivered(${orders?.filter(i => i.orderStatus === 'toBeDelivered')?.length})`} 
                    navCardLink={'/ordersFromStatus/toBeDelivered'}
                >
                    <RiGiftFill />
                </NavCard>
                <NavCard 
                    cardName={`Shipped(${orders?.filter(i => i.orderStatus === 'shipped')?.length})`} 
                    navCardLink={'/ordersFromStatus/shipped'}
                >
                    <RiTruckFill />
                </NavCard>
                <NavCard 
                    cardName={`Completed(${orders?.filter(i => i.orderStatus === 'completed')?.length})`} 
                    navCardLink={'/ordersFromStatus/completed'}
                >
                    <RiCheckboxCircleFill />
                </NavCard>
                <NavCard 
                    cardName={`Canceled(${orders?.filter(i => i.orderStatus === 'canceled')?.length})`} 
                    navCardLink={'/ordersFromStatus/canceled'}
                >
                    <RiCloseCircleFill />
                </NavCard>
            </NavCardList>
        </ScreenHolder>
    )
}

export default OrderScreens