import { Link } from "react-router-dom";
import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";

function AddressScreens() {
  return (
    <ScreenHolder>
      <div>
        <div>
          <Link to={'/areas'} >Areas</Link>
        </div>
        <div>
          <Link to={'/districts'} >Districts</Link>
        </div>
        <div>
          <Link to={'/divisions'} >Divisions</Link>
        </div>
      </div>
    </ScreenHolder>
  )
}

export default AddressScreens