import axios from "axios"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { logout } from "../../../../../Redux/Features/Auth/authSlice"
import { EMPLOYEES_API } from "../../../../../Utilities/APIs"
import ScreenHolder from "../../../../Partials/Layouts/ScreenHolder/ScreenHolder"

function ChangePassword() {

    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [newPass2, setNewPass2] = useState('')

    const {employee} = useSelector(state => state.auth)

    const dispatch = useDispatch()

    async function handleSubmit() {

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        if (newPass !== newPass2) {
            return console.log('Passwords do not match')
        }

        
        const passwordBody = {
            oldPassword: oldPass,
            newPassword: newPass
        }

        const passChange = await axios.patch(EMPLOYEES_API + 'changeEmployeePassword', passwordBody, config)

        if (passChange) {
            setOldPass('')
            setNewPass('')
            setNewPass2('')
            dispatch(logout())
        }
    }

    return (
        <ScreenHolder>
            <div className="form_wrapper">
                <h3>Change Password</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div className="input_group">
                        <label className="input_field_label">current password</label>
                        <input 
                            type="password" 
                            className="input_field body_text" 
                            placeholder='Enter Password'
                            value={oldPass}
                            onChange={e => setOldPass(e.target.value)} 
                        />
                    </div>
                    <div className="input_group">
                        <label className="input_field_label">New password</label>
                        <input 
                            type="password" 
                            className="input_field body_text" 
                            placeholder='Enter Password'
                            value={newPass}
                            onChange={e => setNewPass(e.target.value)} 
                        />
                    </div>
                    <div className="input_group">
                        <label className="input_field_label">confirm New password</label>
                        <input 
                            type="password" 
                            className="input_field body_text" 
                            placeholder='Enter Password'
                            value={newPass2}
                            onChange={e => setNewPass2(e.target.value)} 
                        />
                    </div>
                    <button type='submit' className='btn_text btn_border submit' onClick={handleSubmit}>save new password</button>
                    <Link to='/my-profile' className='btn_text forgot_pass'>cancel</Link>
                </form>
            </div>
        </ScreenHolder>
    )
}

export default ChangePassword