import { RiRhythmLine } from 'react-icons/ri';
import { SIZE_GROUPS_API } from "../../../Utilities/APIs";
import DNCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/DNCRUD/DNCRUD";

function SizeGroups() {
  return (
    <DNCRUD

        // common props
        api={SIZE_GROUPS_API}
        screenTopicSingular='Size Group'
        screenTopicPlural='Size Groups'

        // crud header props
        showTotalInHeading

        // action button props
        extraActionButton
        extraActionButtonHandleClick='/sizesFromSizeGroup'
        extraActionButtonChildren={<RiRhythmLine />}
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete

    />
  )
}

export default SizeGroups