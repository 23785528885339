import axios from "axios"
import { useEffect, useState } from "react"
import { RiCheckboxCircleLine, RiCloseCircleLine, RiGiftLine, RiListSettingsLine, RiRepeatLine, RiTruckLine } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { ORDERS_API } from "../../../../Utilities/APIs"
import Modal from "../../../Partials/Elements/Modal/Modal"
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton"
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton"
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable"
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell"
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader"
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth"
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow"
import ViewOrder from "../ViewOrder"

function OrdersFromStatus() {

    const [orders, setOrders] = useState(null)
    // console.log(orders)
    const [showModal, setShowModal] = useState(false)
    const [targetID, setTargetID] = useState(null)
    const [showViewSection, setShowViewSection] = useState(false)
    const [toggleFetch, setToggleFetch] = useState(false)

    const {employee} = useSelector(state => state.auth)
    console.log(employee.token)
    const {status} = useParams()
    const navigate = useNavigate()

    // console.log(status)

    useEffect(() => {

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        async function fetchAndSetOrders() {
            const {data} = await axios.get(ORDERS_API + 'allOrdersByStatus/' + status, config)
            setOrders(data)
        }
        fetchAndSetOrders()

    }, [employee.token, status, toggleFetch])

    function handleClose() {
        setShowModal(false);
        setShowViewSection(false);
    }

    function buttonIcon() {
        switch (status) {
            case 'processing':
                return <RiGiftLine />
            
            case 'toBeDelivered':
                return <RiTruckLine />

            case 'shipped':
                return <RiCheckboxCircleLine />

            default: 
                return <RiRepeatLine />
        }
    }

    function triggerFetch() {
        setToggleFetch(prevState => !prevState)
    }

    async function handleStatusChange(id) {

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        switch (status) {
            case 'processing':
                const toBeDeliveredFromProcessing = await axios.patch(ORDERS_API + 'toBeDeliveredFromProcessing/' + id, '', config)
                triggerFetch()
                return console.log(toBeDeliveredFromProcessing)
            
            case 'toBeDelivered':
                const shippedFromToBeDelivered = await axios.patch(ORDERS_API + 'shippedFromToBeDelivered/' + id, '',  config)
                triggerFetch()
                return console.log(shippedFromToBeDelivered)

            case 'shipped':
                const completedFromShipped = await axios.patch(ORDERS_API + 'completedFromShipped/' + id, '', config)
                triggerFetch()
                return console.log(completedFromShipped)

            default: 
                return console.log('Status Not Changed')
        }

        
    }

    async function handleCancel(id) {

        const config = {
            headers: {
                Authorization: `Bearer ${employee.token}`
            }
        }

        const canceledOrder = await axios.patch(ORDERS_API + 'canceledOrder/' + id, '', config)
        triggerFetch()
        return console.log(canceledOrder)
        
    }

    return (
        <>
        <div className='crud_holder'>
            <div className="body_header">
                <h1 className="heading">{`${status?.toUpperCase()} Orders (${orders?.length} in total)`}</h1>
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th= 'Order ID' />
                    <CRUDth th= 'Status' />
                    <CRUDth th= 'Total' />
                    <CRUDth th= 'Actions' />
                </CRUDTableHeader>
                <tbody>
                    {orders?.map(order => (
                        <CRUDTableRow key={order._id}>
                            <ShortTextCell text={order._id} />
                            <ShortTextCell text={order.orderStatus} />
                            <ShortTextCell text={order.grandTotal} />
                            <td className="action_button_cell">
                                <ViewButton
                                    setShowModal={setShowModal}
                                    setShowViewSection={setShowViewSection}
                                    targetID={order._id}
                                    setTargetID={setTargetID}
                                />
                                <CRUDButton handleClick={() => navigate('/order_items_from_order/' + order._id)}>
                                    <RiListSettingsLine />
                                </CRUDButton>
                                {
                                    (status === 'processing' || status === 'toBeDelivered' || status === 'shipped') &&
                                    <CRUDButton handleClick={() => handleStatusChange(order._id)}>
                                        {buttonIcon()}
                                    </CRUDButton>
                                }
                                {
                                    (status !== 'completed' && status !== 'canceled') &&
                                    <CRUDButton handleClick={() => handleCancel(order._id)}>
                                        <RiCloseCircleLine />
                                    </CRUDButton>
                                }
                            </td>
                        </CRUDTableRow>
                    ))}
                </tbody>
            </CRUDTable>
        </div>
        {showModal && 
            <Modal
                handleClose={handleClose}
                modalHeading={
                    (showViewSection && `View Order`)
                }
            >
                {
                    showViewSection &&
                        <ViewOrder
                            targetID={targetID}
                            employee={employee}
                        />
                }    
            </Modal>
        }
        </>
    )
}

export default OrdersFromStatus