import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './App.css';

import NavBar from './Partials/Sections/NavBar/NavBar';
import SideBar from './Partials/Sections/SideBar/SideBar';

import Login from './Screens/AuthScreens/Login/Login';

import ItemGroups from './Screens/ItemGroups/ItemGroups';
import Collections from './Screens/ItemGroups/CollectionScreens/Collections';
import NavCollections from './Screens/ItemGroups/CollectionScreens/NavCollections';
import Categories from './Screens/ItemGroups/CategoryScreens/Categories';

import SizeScreens from './Screens/SizeScreens/SizeScreens';
import SizeGroups from './Screens/SizeScreens/SizeGroups';
import Sizes from './Screens/SizeScreens/Sizes';
import FeaturedCategories from './Screens/ItemGroups/CategoryScreens/FeaturedCategories';
import SocialLinks from './Screens/GeneralSettingsScreens/SocialLinks/SocialLinks';
import GeneralSettings from './Screens/GeneralSettingsScreens/GeneralSettings';
import AppSettings from './Screens/GeneralSettingsScreens/AppSettings/AppSettings';
import HeroSliders from './Screens/GeneralSettingsScreens/HeroSliders/HeroSliders';
import Testimonials from './Screens/GeneralSettingsScreens/Testimonials/Testimonials';
import SubCategories from './Screens/ItemGroups/CategoryScreens/SubCategories';

import Items from './Screens/ItemScreens/Items/Items';
import ItemScreens from './Screens/ItemScreens/ItemScreens';
import ItemSizes from './Screens/SizeScreens/ItemSizes';
import FeaturedItems from './Screens/ItemScreens/FeaturedItems/FeaturedItems';
import ItemCategories from './Screens/ItemGroups/ClassifiedItemsScreens/ItemCategories';
import ItemSubcategories from './Screens/ItemGroups/ClassifiedItemsScreens/ItemSubcategories';
import ItemCollections from './Screens/ItemGroups/ClassifiedItemsScreens/ItemCollections';
import AddressScreens from './Screens/AddressScreens/AddressScreens';
import Areas from './Screens/AddressScreens/Areas';
import Divisions from './Screens/AddressScreens/Divisions';
import Districts from './Screens/AddressScreens/Districts';
import ItemAssets from './Screens/ItemScreens/ItemAssets/ItemAssets';
import VAT from './Screens/GeneralSettingsScreens/VAT/VAT';
import Discount from './Screens/GeneralSettingsScreens/Discount/Discount';
import DeliveryFee from './Screens/GeneralSettingsScreens/DeliveryFee/DeliveryFee';
import Wishlist from './Screens/ItemScreens/Wishlist/Wishlist';
import ItemSizesFromItem from './Screens/SizeScreens/ItemSizesFromItem';
import SizesFromSizeGroup from './Screens/SizeScreens/SizesFromSizeGroups';
import ItemAssetsFromItems from './Screens/ItemScreens/ItemAssets/ItemAssetsFromItems';
import Customers from './Screens/UsersScreens/Customers/Customers';
import UsersScreens from './Screens/UsersScreens/UsersScreens';
import WishlistFromCustomer from './Screens/UsersScreens/Customers/WishlistFromCustomer/WishlistFromCustomer';
import AddressesFromCustomer from './Screens/UsersScreens/Customers/AddressesFromCustomer/AddressesFromCustomer';
import OrdersFromCustomer from './Screens/UsersScreens/Customers/OrdersFromCustomer/OrdersFromCustomer';
import OrderItemsFromOrders from './Screens/OrderScreens/OrderItemsFromOrders';
import Dashboard from './Screens/Dashboard/Dashboard';
import CollectionScreens from './Screens/ItemGroups/CollectionScreens/CollectionScreens';
import CategoryScreens from './Screens/ItemGroups/CategoryScreens/CategoryScreens';
import Employees from './Screens/UsersScreens/Employees/Employees';
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees';
import Register from './Screens/AuthScreens/Register/Register';
import OrderScreens from './Screens/OrderScreens/OrderScreens';
import OrdersFromStatus from './Screens/OrderScreens/OrdersFromStatus/OrdersFromStatus';
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne';
// import RecoverPassTwo from './Screens/AuthScreens/RecoverPassTwo';
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree';
import MyProfile from './Screens/MyProfile/MyProfile';
import ChangePassword from './Screens/MyProfile/ProfileInfo/ChangePassword/ChangePassword';
// import EditProfileInfo from './Screens/MyProfile/ProfileInfo/EditProfileInfo/EditProfileInfo';

function App() {

  const { employee } = useSelector(state => state.auth)

  return (
    <>
      <HashRouter>
        {employee && <NavBar />}

        <main>
          {employee && <SideBar />}

          <div className="screen-container">
            <Routes>

              <Route path='/login' element={<Login />} />
              <Route path='/register/:token' element={!employee ? <Register /> : <Navigate to={'/'} />} />

              <Route path={'/'} element={employee ? <Dashboard /> : <Navigate to={'/login'} />} />
              <Route path="/recover-pass-one" element={<RecoverPassOne />} />
              <Route path="/resetEmployeePassword/:token" element={<RecoverPassThree />} />
              <Route path="/profile" element={employee ? <MyProfile /> : <Navigate to='/login' />} />
              <Route path="/change-password" element={employee ? <ChangePassword /> : <Navigate to='/login' />} />

              <Route path={'/user_screens'} element={employee ? <UsersScreens /> : <Navigate to={'/login'} />} />
              <Route path={'/customers'} element={employee ? <Customers /> : <Navigate to={'/login'} />} />
              <Route path={'/employees'} element={employee ? <Employees /> : <Navigate to={'/login'} />} />
              <Route path={'/employee-invites'} element={employee ? <InviteEmployees /> : <Navigate to={'/login'} />} />

              <Route path={'/wishlist_from_customer/:parentID'} element={employee ? <WishlistFromCustomer /> : <Navigate to={'/login'} />} />
              <Route path={'/addresses_from_customer/:customerID'} element={employee ? <AddressesFromCustomer /> : <Navigate to={'/login'} />} />
              <Route path={'/orders_from_customer/:customerID'} element={employee ? <OrdersFromCustomer /> : <Navigate to={'/login'} />} />

              <Route path={'/orderScreens'} element={employee ? <OrderScreens /> : <Navigate to={'/login'} />} />
              <Route path={'/ordersFromStatus/:status'} element={employee ? <OrdersFromStatus /> : <Navigate to={'/login'} />} />

              <Route path={'/order_items_from_order/:orderID'} element={employee ? <OrderItemsFromOrders /> : <Navigate to={'/login'} />} />

              <Route path='/item_screens' element={employee ? <ItemScreens /> : <Navigate to={'/login'} />} />
              <Route path='/items' element={employee ? <Items /> : <Navigate to={'/login'} />} />
              <Route path='/item_assets_from_items/:parentID' element={employee ? <ItemAssetsFromItems /> : <Navigate to={'/login'} />} />
              <Route path='/item_assets' element={employee ? <ItemAssets /> : <Navigate to={'/login'} />} />
              <Route path='/featured_items' element={employee ? <FeaturedItems /> : <Navigate to={'/login'} />} />
              <Route path='/wishlists' element={employee ? <Wishlist /> : <Navigate to={'/login'} />} />

              <Route path='/item_collections/:parentID' element={employee ? <ItemCollections /> : <Navigate to={'/login'} />} />
              <Route path='/item_categories/:parentID' element={employee ? <ItemCategories /> : <Navigate to={'/login'} />} />
              <Route path='/item_subcategories/:parentID' element={employee ? <ItemSubcategories /> : <Navigate to={'/login'} />} />

              <Route path='/item_groups' element={employee ? <ItemGroups /> : <Navigate to={'/login'} />} />

              <Route path='/collection_screens' element={employee ? <CollectionScreens /> : <Navigate to={'/login'} />} />
              <Route path='/collections' element={employee ? <Collections /> : <Navigate to={'/login'} />} />
              <Route path='/nav_collections' element={employee ? <NavCollections /> : <Navigate to={'/login'} />} />

              <Route path='/category_screens' element={employee ? <CategoryScreens /> : <Navigate to={'/login'} />} />
              <Route path='/categories' element={employee ? <Categories /> : <Navigate to={'/login'} />} />
              <Route path='/featured_categories' element={employee ? <FeaturedCategories /> : <Navigate to={'/login'} />} />
              <Route path='/subcategories/:parentID' element={employee ? <SubCategories /> : <Navigate to={'/login'} />} />

              <Route path='/size_screens' element={employee ? <SizeScreens /> : <Navigate to={'/login'} />} />
              <Route path='/size_groups' element={employee ? <SizeGroups /> : <Navigate to={'/login'} />} />
              <Route path='/sizesFromSizeGroup/:parentID' element={employee ? <SizesFromSizeGroup /> : <Navigate to={'/login'} />} />
              <Route path='/sizes' element={employee ? <Sizes /> : <Navigate to={'/login'} />} />
              <Route path='/item_sizes' element={employee ? <ItemSizes /> : <Navigate to={'/login'} />} />
              <Route path='/itemSizesFromItem/:parentID' element={employee ? <ItemSizesFromItem /> : <Navigate to={'/login'} />} />

              <Route path='/address_screens' element={employee ? <AddressScreens /> : <Navigate to={'/login'} />} />
              <Route path='/areas/:parentID' element={employee ? <Areas /> : <Navigate to={'/login'} />} />
              <Route path='/districts/:parentID' element={employee ? <Districts /> : <Navigate to={'/login'} />} />
              <Route path='/divisions' element={employee ? <Divisions /> : <Navigate to={'/login'} />} />

              <Route path='/vat' element={employee ? <VAT /> : <Navigate to={'/login'} />} />
              <Route path='/discounts' element={employee ? <Discount /> : <Navigate to={'/login'} />} />
              <Route path='/delivery-fees' element={employee ? <DeliveryFee /> : <Navigate to={'/login'} />} />

              <Route path='/general_settings' element={employee ? <GeneralSettings /> : <Navigate to={'/login'} />} />
              <Route path='/social_links' element={employee ? <SocialLinks /> : <Navigate to={'/login'} />} />
              <Route path='/hero_sliders' element={employee ? <HeroSliders /> : <Navigate to={'/login'} />} />
              <Route path='/testimonials' element={employee ? <Testimonials /> : <Navigate to={'/login'} />} />
              <Route path='/app_settings' element={employee ? <AppSettings /> : <Navigate to={'/login'} />} />

            </Routes>
          </div>
        </main>
      </HashRouter>
    </>
  );
}

export default App;
