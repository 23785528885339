import { CUSTOMERS_API, ITEMS_API, WISHLIST_API } from "../../../../Utilities/APIs";
import SSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/SSCRUD";

function Wishlist() {
  return (
    <SSCRUD
        api={WISHLIST_API}
        screenTopicSingular={'Wishlist'}
        screenTopicPlural={'Wishlists'}
        
        // crud header props
        showTotalInHeading
        
        // select props
        selectApi={CUSTOMERS_API + 'getAllCustomers'}
        selectType={'parent-child'}
        selectDBField={'customer'}
        selectName={'Customer'}

        // secondSelect props
        secondSelectApi={ITEMS_API}
        secondSelectName={'Item'}
        secondSelectDBField={'item'}
    />
  )
}

export default Wishlist