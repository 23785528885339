import { TESTIMONIALS_API } from "../../../../Utilities/APIs"
import ILtNPTCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/ILtNPTCRUD/ILtNPTCRUD"

function Testimonials() {
    return (
        <ILtNPTCRUD
            // common props
            api={TESTIMONIALS_API}
            screenTopicSingular={'Testimonial'}
            screenTopicPlural={'Testimonials'}
            
            // crud header props
            showTotalInHeading

            // crud props
            allowCreate
            allowUpdate
            allowDelete

            // field props
            maxPrecedence={10}
            textDBField='designation'
            textName='Designation'
            longTextDBField='testimonial'
            longTextName='Testimonial'
        />
    )
}

export default Testimonials