import { RiCollageLine, RiGridLine } from "react-icons/ri";
import { CATEGORIES_API } from "../../../../Utilities/APIs";
import DINCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/DINCRUD/DINCRUD";

function Categories() {
  return (
    <DINCRUD
        // common props
        api={CATEGORIES_API}
        screenTopicSingular={'Category'}
        screenTopicPlural={'Categories'}
        
        // crud header props
        showTotalInHeading

        // action button props
        extraActionButton
        extraActionButtonHandleClick='/subcategories'
        extraActionButtonChildren={<RiCollageLine />}
        secondExtraActionButton
        secondExtraActionButtonHandleClick='/item_categories'
        secondExtraActionButtonChildren={<RiGridLine />}
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete
    />
  )
}

export default Categories