import { SIZES_API, SIZE_GROUPS_API } from "../../../Utilities/APIs";
import DNPSCRUD from "../../Partials/Layouts/CRUDs/CRUDLayouts/DNPSCRUD/DNPSCRUD";

function Sizes() {
  return (
    <DNPSCRUD
      // common props
      api={SIZES_API}
      screenTopicSingular={'Size'}
      screenTopicPlural={'Sizes'}
      
      // crud header props
      showTotalInHeading
      
      // crud props
      allowCreate
      allowUpdate
      allowDelete

      // field props
      maxPrecedence={8}
      
      // select props
      selectApi={SIZE_GROUPS_API}
      selectType='parent-child'
      selectDBField='sizeGroup'
      selectName='Size Group'
    />
  )
}

export default Sizes