export const BASE_URL = 'http://backend.bornilagro.com/'

export const API_URL = BASE_URL + 'api/'

export const LOCATION_API = 'http://backend.anthill.club/api/'

export const EMPLOYEES_API = API_URL + 'employees/'
export const CUSTOMERS_API = API_URL + 'customers/'

export const ADDRESSES_API = API_URL + 'addresses/'

export const ORDERS_API = API_URL + 'orders/'

export const ITEMS_API = API_URL + 'items/'
export const ITEM_ASSETS_API = API_URL + 'itemAssets/'
export const FEATURED_ITEMS_API = API_URL + 'featuredItems/'
export const WISHLIST_API = API_URL + 'wishlists/'

export const ITEM_COLLECTIONS_API = API_URL + 'itemCollections/'
export const ITEM_CATEGORIES_API = API_URL + 'itemCategories/'
export const ITEM_SUBCATEGORIES_API = API_URL + 'itemSubcategories/'

export const CATEGORIES_API = API_URL + 'categories/'
export const FEATURED_CATEGORIES_API = API_URL + 'featuredCategories/'
export const SUBCATEGORIES_API = API_URL + 'subCategories/'

export const COLLECTIONS_API = API_URL + 'collections/'
export const NAV_COLLECTIONS_API = API_URL + 'navCollections/'

export const SIZE_GROUPS_API = API_URL + 'sizeGroups/'
export const SIZES_API = API_URL + 'sizes/'
export const ITEM_SIZES_API = API_URL + 'itemSizes/'

export const AREAS_API = LOCATION_API + 'areas/'
export const DISTRICTS_API = LOCATION_API + 'districts/'
export const DIVISIONS_API = LOCATION_API + 'divisions/'


export const VATS_API = API_URL + 'vats/'
export const DISCOUNTS_API = API_URL + 'discounts/'
export const DELIVERY_FEES_API = API_URL + 'deliveryFees/'

export const SOCIAL_LINKS_API = API_URL + 'socialLinks/'
export const HERO_SLIDERS_API = API_URL + 'heroSliders/'
export const TESTIMONIALS_API = API_URL + 'testimonials/'
export const APP_SETTINGS_API = API_URL + 'appSettings/'