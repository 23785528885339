import { RiGridLine } from "react-icons/ri"
import { COLLECTIONS_API } from "../../../../Utilities/APIs"
import DNCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/DNCRUD/DNCRUD"

function Collections() {
  return (
    <DNCRUD
        // common props
        api={COLLECTIONS_API}
        screenTopicSingular='Collection'
        screenTopicPlural='Collections'

        // crud header props
        showTotalInHeading

        // action button props
        extraActionButton
        extraActionButtonHandleClick='/item_collections'
        extraActionButtonChildren={<RiGridLine />}
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete
    />
  )
}

export default Collections