import { RiChatQuoteFill, RiFacebookBoxFill, RiImage2Fill, RiPercentFill, RiPriceTag2Fill, RiSettings2Fill, RiTruckFill } from "react-icons/ri";

import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";

function GeneralSettings() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={'seven'}>
        <NavCard cardName={'VAT'} navCardLink='/vat' ><RiPercentFill /></NavCard>
        <NavCard cardName={'Discounts'} navCardLink='/discounts' ><RiPriceTag2Fill /></NavCard>
        <NavCard cardName={'Delivery Fees'} navCardLink='/delivery-fees' ><RiTruckFill /></NavCard>
        <NavCard cardName={'Hero Sliders'} navCardLink='/hero_sliders' ><RiImage2Fill /></NavCard>
        <NavCard cardName={'Social Links'} navCardLink='/social_links' ><RiFacebookBoxFill /></NavCard>
        <NavCard cardName={'Testimonials'} navCardLink='/testimonials' ><RiChatQuoteFill /></NavCard>
        <NavCard cardName={'App Settings'} navCardLink='/app_settings' ><RiSettings2Fill /></NavCard>
      </NavCardList>
    </ScreenHolder>
  )
}

export default GeneralSettings