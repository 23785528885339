import { CATEGORIES_API, FEATURED_CATEGORIES_API } from "../../../../Utilities/APIs";
import PSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/PSCRUD/PSCRUD";

function FeaturedCategories() {
  return (
    <PSCRUD
        // common props
        api={FEATURED_CATEGORIES_API}
        screenTopicSingular={'Featured Category'}
        screenTopicPlural={'Featured Categories'}
        
        // crud header props
        showTotalInHeading
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete

        // field props
        maxPrecedence={8}
        
        // select props
        selectApi={CATEGORIES_API}
        selectType='hand-pick'
        selectDBField='category'
        selectName='Category'
    />
  )
}

export default FeaturedCategories