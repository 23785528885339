import {RiMapPin2Fill} from "react-icons/ri";

import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";

function UsersScreens() {
  return (
      <NavCardList numOfCards={'two'}>
        <NavCard cardName={'Employeees'} navCardLink='/employees' ><RiMapPin2Fill /></NavCard>
        <NavCard cardName={'Customers'} navCardLink='/customers' ><RiMapPin2Fill /></NavCard>
        <NavCard cardName={'Employee Invites'} navCardLink='/employee-invites' ><RiMapPin2Fill /></NavCard>
      </NavCardList>
  )
}

export default UsersScreens