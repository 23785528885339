import { RiGridFill, RiMedal2Fill } from "react-icons/ri"
import NavCard from "../../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../../Partials/Layouts/NavCardList/NavCardList"

function CollectionScreens() {
    return (
        <NavCardList numOfCards={'two'}>
            <NavCard cardName={'Collections'} navCardLink='/collections' ><RiGridFill /></NavCard>
            <NavCard cardName={'Nav Collections'} navCardLink='/nav_collections' ><RiMedal2Fill /></NavCard>
        </NavCardList>
    )
}

export default CollectionScreens