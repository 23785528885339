import { DISCOUNTS_API } from '../../../../Utilities/APIs'
import ABCRUD from '../../../Partials/Layouts/CRUDs/CRUDLayouts/ABCRUD/ABCRUD'

function Discount() {
  return (
    <ABCRUD

         // common props
         api={DISCOUNTS_API}
         screenTopicSingular={'Discount'}
         screenTopicPlural={'Discounts'}
 
         // crud header props
         showTotalInHeading
 
         // crud props
         allowUpdate
 
         // field props
         booleanName={'Is Applicable'}
         booleanDBField={'isApplicable'}

    />
  )
}

export default Discount