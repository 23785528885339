import { RiMapPin2Fill } from "react-icons/ri";

import ScreenHolder from "../../Partials/Layouts/ScreenHolder/ScreenHolder";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";

function SizeScreens() {
  return (
    <ScreenHolder>
      <NavCardList numOfCards={'two'}>
        <NavCard cardName={'Size Groups'} navCardLink='/size_groups' ><RiMapPin2Fill /></NavCard>
        <NavCard cardName={'Sizes'} navCardLink='/sizes' ><RiMapPin2Fill /></NavCard>
        <NavCard cardName={'Item Sizes'} navCardLink='/item_sizes' ><RiMapPin2Fill /></NavCard>
      </NavCardList>
      {/* <div>
        <div>
          <Link to={'/size_groups'} >Size Groups</Link>
        </div>
        <div>
          <Link to={'/sizes'} >Sizes</Link>
        </div>
        <div>
          <Link to={'/item_sizes'} >Item Sizes</Link>
        </div>
      </div> */}
    </ScreenHolder>
  )
}

export default SizeScreens