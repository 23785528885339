import {RiMapPin2Fill} from "react-icons/ri";

import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";

function ItemScreens() {
  return (
      <NavCardList numOfCards={'three'}>
        <NavCard cardName={'Items'} navCardLink='/items' ><RiMapPin2Fill /></NavCard>
        <NavCard cardName={'Featured Items'} navCardLink='/featured_items' ><RiMapPin2Fill /></NavCard>
      </NavCardList>
  )
}

export default ItemScreens