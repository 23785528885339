import { FEATURED_ITEMS_API, ITEMS_API } from '../../../../Utilities/APIs'
import PSCRUD from '../../../Partials/Layouts/CRUDs/CRUDLayouts/PSCRUD/PSCRUD'

function FeaturedItems() {
  return (
    <PSCRUD
        // common props
        api={FEATURED_ITEMS_API}
        screenTopicSingular={'Featured Item'}
        screenTopicPlural={'Featured Items'}
        
        // crud header props
        showTotalInHeading
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete

        // field props
        maxPrecedence={8}
        
        // select props
        selectApi={ITEMS_API}
        selectType='hand-pick'
        selectDBField='item'
        selectName='Item'
    />
  )
}

export default FeaturedItems