import { ITEMS_API, ITEM_ASSETS_API } from '../../../../Utilities/APIs'
import PcIPSCRUD from '../../../Partials/Layouts/CRUDs/CRUDLayouts/IPSCRUD/PcIPSCRUD'

function ItemAssets() {
  return (
    <PcIPSCRUD
        // common props
        api={ITEM_ASSETS_API}
        screenTopicSingular={'Item Asset'}
        screenTopicPlural={'Item Assets'}
        
        // crud header props
        showTotalInHeading
        
        // crud props
        allowCreate
        allowUpdate
        allowDelete
        
        // field props
        maxPrecedence={4}
        
        // select props
        selectApi={ITEMS_API}
        selectType={'parent-child'}
        selectDBField={'item'}
        selectName={'Item'}
    />
  )
}

export default ItemAssets