import './SelectOption.css'

function SelectOption({optionValue, optionText, hidden}) {
  return (
    <option
        value={optionValue}
        hidden={hidden}
    >{optionText}</option>
  )
}

export default SelectOption