import { useState, useEffect } from 'react'
import { APP_SETTINGS_API, BASE_URL } from '../../../../Utilities/APIs'

function Logo() {

    const [logo, setLogo] = useState(null)
	
	useEffect(() => {

        async function fetchData() {
            const res = await fetch(APP_SETTINGS_API);
            const data = await res.json();
            setLogo(data[0]?.image)
        }

		fetchData()
        
	}, [])
    
    return (
        <>
            {logo && <img src={BASE_URL + logo.slice(6,logo.length)} alt="logo" />}
        </>
    )
}

export default Logo