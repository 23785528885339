import { RiCollageFill, RiGridFill } from "react-icons/ri";

import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";

function ItemGroups() {
  return (
      <NavCardList numOfCards={'two'}>
        <NavCard cardName={'Collection Screens'} navCardLink='/collection_screens' ><RiGridFill /></NavCard>
        <NavCard cardName={'Category Screens'} navCardLink='/category_screens' ><RiCollageFill /></NavCard>
      </NavCardList>
  )
}

export default ItemGroups