import { RiBuildingLine, RiSettings4Line, RiBriefcase2Line, RiShoppingCart2Line, RiMapPin2Line, RiUser2Line, RiGiftLine, RiRhythmLine } from 'react-icons/ri'

import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';

import './SideBar.css';

function SideBar() {

    return (
        <>
            <section className="small_sidebar">
                <div className='container'>
                    <SmallSideBarItem>
                        <RiBuildingLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem link={'/item_screens'}>
                        <RiGiftLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem link={'/item_groups'}>
                        <RiBriefcase2Line />
                    </SmallSideBarItem>
                    <SmallSideBarItem link={'/size_groups'}>
                        <RiRhythmLine />
                    </SmallSideBarItem>
                    <SmallSideBarItem link={'/orderScreens'}>
                        <RiShoppingCart2Line />
                    </SmallSideBarItem>
                    <SmallSideBarItem  link={'/user_screens'}>
                        <RiUser2Line />
                    </SmallSideBarItem>
                    <SmallSideBarItem link={'/divisions'}>
                        <RiMapPin2Line />
                    </SmallSideBarItem>
                    <SmallSideBarItem link={'/general_settings'}>
                        <RiSettings4Line />
                    </SmallSideBarItem>
                </div>
            </section>
        </>
    )
}

export default SideBar
