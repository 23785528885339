import { COLLECTIONS_API, ITEMS_API, ITEM_COLLECTIONS_API } from "../../../../Utilities/APIs"
import PcSSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/PcSSCRUD"
// import SSCRUD from "../../../Partials/Layouts/CRUDs/CRUDLayouts/SSCRUD/SSCRUD"

function ItemCollections() {
  return (
    <PcSSCRUD

        // parentChild props
        parentDBField='collectionID'

        // common props
        api={ITEM_COLLECTIONS_API}
        screenTopicSingular='Item Collection'
        screenTopicPlural='Item Collections'

        // crud header props
        showTotalInHeading

        // crud props
        allowCreate
        allowUpdate
        allowDelete

        // select props
        selectApi={COLLECTIONS_API}
        selectType='parent-child'
        selectDBField='collectionID'
        selectName='Collection'

        // secondSelect props
        secondSelectApi={ITEMS_API}
        secondSelectName='Item'
        secondSelectDBField='item'
    />
  )
}

export default ItemCollections