import axios from "axios";
import { useEffect, useState } from "react";
import { ORDERS_API } from "../../../Utilities/APIs";

function ViewOrder({employee, targetID}) {

  const [order, setOrder] = useState(null)

  useEffect(() => {

    const config = {
        headers: {
            Authorization: `Bearer ${employee.token}`
        }
    }

    async function fetchAndSetOrder() {
    
        const {data} = await axios.get(ORDERS_API + 'getSingleOrder/' + targetID, config)

        setOrder(data[0]);
    }

    fetchAndSetOrder()

    
  }, [targetID, employee.token])

  return (
    <div className="crud_view_content">

        <h1>Area</h1>
        <p>{order?.areaString}</p>

        <h1>Full Address</h1>
        <p>{order?.fullAddressString}</p>

        <h1>Subtotal</h1>
        <p>{order?.subtotal}</p>

        <h1>Discount</h1>
        <p>{order?.areaString}</p>

        <h1>Delivery Fee</h1>
        <p>{order?.deliveryFee}</p>

        <h1>Total</h1>
        <p>{order?.total}</p>

        <h1>VAT</h1>
        <p>{order?.vat}</p>

        <h1>Grand Total</h1>
        <p>{order?.grandTotal}</p>

    </div>
  )
}

export default ViewOrder