import { NavLink } from 'react-router-dom'

import './SmallSideBarItem.css'

function SmallSideBarItem({link, children}) {
  return (
    <div className='small_sidebar_item'>
        <NavLink to={link ? link : '/'}>
            <button type="button" >
                {children}
            </button>
        </NavLink>
    </div>
  )
}

export default SmallSideBarItem